<template>
  <div id="app">
    <van-nav-bar title="备件详情" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="common-wrap">
      <van-form>
        <van-field v-model="info.coding" label="物料号" />
        <van-field v-model="info.name" label="物料描述" />
        <van-field v-model="info.unit" label="计量单位" />
        <van-field v-model="info.spec" label="规格型号" />
        <van-field v-model="info.store.name" label="存储地点" />
        <van-field v-model="info.location_x" label="库位行数" />
        <van-field v-model="info.location_y" label="库位列数" />
        <van-field v-model="info.number" label="当前数量" />
      </van-form>
    </div>
  </div>
</template>
<script>
import API from '@/api/stock/parts'
export default {
  data() {
    return {
      info: {
        store: {

        }
      }
    }
  },
  created() {
    this.index()
  },
  methods: {
    index() {
      API.getOne(this.$route.params.id).then(res => {
        console.log(res.data)
        this.info = res.data
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>

</style>
