import request from '@/utils/request'

export default {
    partsList(params) {
        return request({
            url: '/v1/partsList',
            method: 'get',
            params
        })
    },
    getOne(id) {
        return request({
            url: `/v1/parts/${id}`,
            method: 'get'
        })
    },
    update(data) {
        return request({
            url: '/v1/partsUpdate',
            method: 'post',
            data
        })
    },
    create(data) {
        return request({
            url: '/v1/partsAdd',
            method: 'post',
            data
        })
    },
    delete(id) {
        return request({
            url: `/v1/partsDel/${id}`,
            method: 'get'
        })
    },
    renew(id) {
        return request({
            url: `/v1/partsRenew/${id}`,
            method: 'get'
        })
    },
    usedEq(data) {
        return request({
            url: '/v1/partsUsedEqAppend',
            method: 'post',
            data
        })
    },
    delUsedEq(data) {
        return request({
            url: '/v1/partsUsedEqRemove',
            method: 'post',
            data
        })
    },
    usedSpecial(data) {
        return request({
            url: '/v1/partsUsedSpecialAppend',
            method: 'post',
            data
        })
    },
    delUsedSpecial(data) {
        return request({
            url: '/v1/partsUsedSpecialRemove',
            method: 'post',
            data
        })
    },
    usedEqCategory(data) {
        return request({
            url: '/v1/partsUsedEqCategoryAppend',
            method: 'post',
            data
        })
    },
    delUsedEqCategory(data) {
        return request({
            url: '/v1/partsUsedEqCategoryRemove',
            method: 'post',
            data
        })
    },
    usedSpecialCategory(data) {
        return request({
            url: '/v1/partsUsedSpecialCategoryAppend',
            method: 'post',
            data
        })
    },
    delUsedSpecialCategory(data) {
        return request({
            url: '/v1/partsUsedSpecialCategoryRemove',
            method: 'post',
            data
        })
    },
    usedJig(data) {
        return request({
            url: '/v1/partsUsedJigAppend',
            method: 'post',
            data
        })
    },
    delUsedJig(data) {
        return request({
            url: '/v1/partsUsedJigRemove',
            method: 'post',
            data
        })
    },
    partsProvider(data) {
        return request({
            url: '/v1/partsProviderAppend',
            method: 'post',
            data
        })
    },
    delPartsProvider(data) {
        return request({
            url: '/v1/partsProviderRemove',
            method: 'post',
            data
        })
    },
    excel(data) {
        return request({
            url: '/v1/partsExcel',
            method: 'post',
            data
        })
    }
}
